<template>
  <v-card>
    <CardHeader>
      <template slot="left">
        <div class="pa-4">
          <v-card color="#525252" flat height="29px" width="176px">
            <div class="d-flex justify-center align-center">
              <div class="font-18px white--text">
                共有アカウント
              </div>
            </div>
          </v-card>
        </div>
      </template>
      <template slot="right">
        <template v-if="sharedAccountMode && sharedAccountMode == 'edit'">
          <p class="mr-8  mouse-pointer">
            <v-icon v-if="formDisplay" @click="formDisplay = false" small
              >$customChevronUp</v-icon
            >
            <v-icon v-else @click="formDisplay = true" small
              >$customChevronDown</v-icon
            >
          </p>
        </template>
        <template v-else>
          <v-btn
            class="font-14px mr-8"
            color="transparent"
            text
            @click="changeToCreateMode()"
          >
            <v-icon color="primary">mdi-plus</v-icon>
            <span class="primary--text">新規登録</span>
          </v-btn>
        </template>
      </template>
    </CardHeader>
    <v-expand-transition>
      <validation-observer ref="observer">
        <v-form
          ref="form"
          @submit.prevent="submit"
          v-show="formDisplay"
          class="shared-cls"
        >
          <v-row dense class="pt-8 pb-6" justify="space-between">
            <!-- form -->
            <v-col class="ml-13" cols="5">
              <!-- id -->
              <InputFormElement
                :useCompute="false"
                :labelInputSpace="false"
                labelWidth="2"
                label="ID"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="id"
                  rules="required|min:8"
                >
                  <v-text-field
                    class="ml-4 mr-8"
                    dense
                    height="27px"
                    outlined
                    v-model="accountID"
                    placeholder="ID"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </InputFormElement>

              <!-- password -->
              <InputFormElement
                :useCompute="false"
                labelWidth="2"
                :labelInputSpace="false"
                :label="$t('page_store_shared_password')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="password"
                  rules="required:パスワード |min:8"
                >
                  <v-text-field
                    class="ml-4 mr-8"
                    dense
                    height="27px"
                    outlined
                    placeholder="パスワード"
                    v-model="accountPassword"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </InputFormElement>
            </v-col>
            <!-- suggestions -->
            <v-col cols="5" class="mr-15">
              <InputFormElement
                :label="$t('member')"
                :labelInputSpace="false"
                :useCompute="false"
                labelWidth="2"
                class="suggestions"
              >
                <div class="member-list ml-4 px-3">
                  <div
                    v-for="item in members"
                    class="d-flex align-baseline "
                    :key="item.id"
                  >
                    <span class="text-a0a0a0">{{ item.id }}.</span>
                    <v-text-field
                      @keyup="addNewField()"
                      class="ml-2"
                      height="24"
                      flat
                      dense
                      v-model="item.value"
                      hide-details
                      placeholder="追加のメンバー名を入力してください。"
                    ></v-text-field>
                  </div>
                </div>
              </InputFormElement>
            </v-col>
          </v-row>
          <!-- submit buttons -->
          <v-row no-gutters class="pb-8">
            <v-col>
              <v-btn
                text
                :loading="loading"
                class="ml-8 text-red font-weight-bold "
                @click="openConfirmation"
                :disabled="sharedAccountMode != 'edit'"
              >
                {{ $t("page_store_registeration_delete") }}
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-btn
                :loading="loading"
                width="84px"
                height="24px"
                type="submit"
                color="primary"
                >{{ $t("page_store_shared_account_save") }}</v-btn
              >
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-expand-transition>
  </v-card>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
import InputFormElement from "@/components/admin/partials/Shops/partials/InputFormElement";
import { mapGetters } from "vuex";
export default {
  components: {
    CardHeader,
    InputFormElement
  },
  data() {
    return {
      formDisplay: false,
      accountPassword: null,
      accountID: null,
      sharedAccountMode: null,
      loading: false,
      members: [
        {
          id: 1,
          value: "",
          name: `input_1`,
          type: "text",
          placeholder: `追加のメンバー名を入力してください。`,
          rules: ""
        },
        {
          id: 2,
          value: "",
          name: `input_2`,
          type: "text",
          placeholder: `追加のメンバー名を入力してください。`,
          rules: ""
        },
        {
          id: 3,
          value: "",
          name: `input_3`,
          type: "text",
          placeholder: `追加のメンバー名を入力してください。`,
          rules: ""
        },
        {
          id: 4,
          value: "",
          name: `input_4`,
          type: "text",
          placeholder: `追加のメンバー名を入力してください。`,
          rules: ""
        },
        {
          id: 5,
          value: "",
          name: `input_5`,
          type: "text",
          placeholder: `追加のメンバー名を入力してください。`,
          rules: ""
        }
      ]
    };
  },
  created() {},
  computed: {
    ...mapGetters(["getShop"]),
    lastIncrementField() {
      return this.members[this.members.length - 1];
    },
    secondlastIncrementField() {
      return this.members[this.members.length - 2];
    }
  },
  watch: {
    getShop: {
      deep: true,
      handler(val) {
        if (val) {
          this.fillData();
        }
      }
    }
  },
  methods: {
    fillData() {
      if (this.getShop?.shared_account?.id) {
        this.sharedAccountMode = "edit";
        this.formDisplay = true;
        let sharedAccount = this.getShop.shared_account;
        let sharedAccountNames = this.getShop.shared_account?.shared_accounts;

        this.accountID = sharedAccount.s_account_id;
        this.accountPassword = sharedAccount.raw_password;

        if (sharedAccountNames && sharedAccountNames.length) {
          sharedAccountNames.forEach((field, index) => {
            if (!this.members[index]) {
              this.addNewField();
            }
            this.members[index].value = field.name;
          });
          this.addNewField();
        }
      } else {
        this.sharedAccountMode = "create";
      }
    },

    addNewField() {
      let fieldsLength = this.members.length;
      if (this.lastIncrementField.value != "") {
        this.members.push({
          id: `${fieldsLength + 1}`,
          name: `input_${fieldsLength + 1}`,
          type: "text",
          placeholder: `追加のメンバー名を入力してください。`,
          value: "",
          rules: "",
          newAdded: true
        });
      } else if (
        (!this.lastIncrementField.newAdded ||
          this.secondlastIncrementField.value == "") &&
        fieldsLength > 5
      ) {
        let lastIndex = this.members.indexOf(this.lastIncrementField);
        this.members.splice(lastIndex, 1);
      }
    },
    refreshedShop() {
      this.loading = true;
      this.$store
        .dispatch("SHOP_GET", { id: this.$route.params.shop_id })
        .finally(() => {
          this.loading = false;
        });
    },
    async openConfirmation() {
      const result = await this.$root.confirm.open(
        this.$t("page_store_shared_account_confirmation_alert") +
          "\n" +
          this.$t("delete_confirmation_alert"),
        "Are you sure?",
        { color: "red" }
      );
      if (result && this.sharedAccountMode == "edit") {
        this.loading = true;
        this.$store
          .dispatch("SHARED_ACCOUNT_DELETE", {
            id: this.getShop.shared_account.id,
            shop_id: this.$route.params.shop_id
          })
          .then(() => {
            this.$refs.form.reset();
            this.refreshedShop();
          })
          .finally(() => (this.loading = false));
      }
    },
    changeToCreateMode() {
      this.formDisplay = true;
      this.sharedAccountMode = "create";
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false;
          return;
        }

        this.loading = true;
        let data = {};
        data.id =
          this.sharedAccountMode == "edit"
            ? this.getShop?.shared_account?.id
            : 0;
        data.s_account_id = this.accountID;
        data.shop_id = this.$route.params.shop_id;
        data.password = this.accountPassword;

        data.names = this.members
          .filter(item => {
            if (item.value) {
              return item.value;
            }
          })
          .map(item => item.value);
        this.loading = false;

        if (this.sharedAccountMode == "edit") {
          this.$store
            .dispatch("SHARED_ACCOUNT_UPDATE", data)
            .then(() => {
              this.refreshedShop();
            })
            .finally(() => (this.loading = false));
        } else {
          this.$store
            .dispatch("SHARED_ACCOUNT_CREATE", data)
            .then(() => {
              this.sharedAccountMode = "edit";
              this.refreshedShop();
            })
            .finally(() => (this.loading = false));
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped src="./style.scss"></style>
