<template>
  <div v-frag class="">
    <Parent />
    <ShopEdit class="mt-3"> </ShopEdit>
    <SharedAccount class="mt-8"></SharedAccount>
    <InviteUserDialog
      v-model="dialog"
      @close-dialog="dialog = false"
      @reload-item="getDataFromApi"
    />

    <CardHeader class="mt-8">
      <template slot="left">
        <div class="pa-5">
          <v-card color="#525252" flat height="28px" class="ml-1" width="128px">
            <div class="d-flex justify-center align-center">
              <v-icon color="white" size="20">$users</v-icon>
              <div class="font-18px white--text pl-2">
                {{ $t("user_name") }}
              </div>
            </div>
          </v-card>
        </div>
      </template>
      <template slot="right">
        <v-row class="mr-4">
          <v-col cols="auto">
            <v-btn text color="primary" @click="showInvitationDialog">
              <v-icon left>
                mdi-plus
              </v-icon>
              新規登録
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn
              :ripple="false"
              text
              fab
              x-small
              @click="showTables = !showTables"
              color="#525252"
            >
              <v-icon>
                {{ chevronIcon }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </CardHeader>
    <v-expand-transition>
      <Table
        :headers="userHeaders"
        :items="users"
        :total-records="users.length"
        class="edit-table"
        v-show="showTables"
      >
        <template v-slot:item.user_role="{ item }">
          {{ item.user_role | showRoles }}
        </template>
        <template v-slot:item.avatar="{ item }">
          <v-avatar color="avatar-bg-grey" size="36" class="white--text">
            <v-img :src="item.avatar"></v-img>
          </v-avatar>
        </template>
        <template v-slot:item.surname="{ item }">
          {{ item.surname + " " + item.name }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate("YYYY-MM-DD") }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn
            text
            rounded
            :to="{
              name: 'UserShopEdit',
              params: { shop_id: getShop.id, user_id: item.user_shop_id }
            }"
          >
            <v-icon size="16">$edit</v-icon>
          </v-btn>
        </template>
      </Table>
    </v-expand-transition>
  </div>
</template>
<script>
import ShopEdit from "@/components/admin/partials/Shops/ShopEdit";
import CardHeader from "@/components/admin/ui/CardHeader";
import Table from "@/components/admin/partials/Table/Table";
import SharedAccount from "@/components/admin/partials/Shops/SharedAccount";
import Parent from "@/views/admin/Store/Index";
import { mapGetters } from "vuex";
import InviteUserDialog from "@/components/admin/partials/Shops/partials/InviteUserDialog";

export default {
  components: {
    InviteUserDialog,
    ShopEdit,
    CardHeader,
    Table,
    SharedAccount,
    Parent
  },
  filters: {
    showRoles: value => {
      switch (value) {
        case "M":
          return "マスター";
        case "P":
          return "パート";
      }
    }
  },
  data() {
    return {
      showTables: true,
      userHeaders: [
        { text: this.$t("id"), align: "center", value: "id", sortable: false },
        {
          text: "",
          align: "center",
          value: "avatar",
          width: "25",
          class: "px-0",
          sortable: false
        },
        {
          text: this.$t("page_users_list_table_header_user_name"),
          align: "center",
          value: "surname",
          width: "150",
          sortable: false
        },
        {
          text: this.$t("page_users_list_table_header_position"),
          align: "center",
          value: "position",
          sortable: false
        },
        {
          text: this.$t("page_users_list_table_header_email"),
          align: "center",
          value: "email",
          sortable: false
        },
        {
          text: this.$t("page_users_list_table_header_group"),
          align: "center",
          value: "user_role",
          sortable: false
        },
        {
          text: this.$t("page_users_list_table_header_registeration_date"),
          align: "center",
          value: "created_at",
          sortable: false
        },
        {
          text: "",
          value: "action",
          align: "center",
          sortable: false
        }
      ],
      dialog: false
    };
  },
  async mounted() {
    await this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getShop"]),
    chevronIcon() {
      return this.showTables ? "$customChevronUp" : "$customChevronDown";
    },
    users() {
      const notSUsers = [];
      if (Object.keys(this.getShop).length > 0) {
        this.getShop.user_shop.forEach(item => {
          if (item.user_role !== "S") {
            const row = {
              id: item.user_id,
              user_shop_id: item.id,
              user_role: item.user_role,
              surname: item?.user?.surname,
              name: item?.user?.name,
              position: item?.user?.position,
              email: item?.user?.email,
              created_at: item?.user?.created_at,
              avatar: item?.user?.avatar
            };
            notSUsers.push(row);
          }
        });
      }
      return notSUsers;
    }
  },
  methods: {
    getDataFromApi() {
      this.$store
        .dispatch("SHOP_GET", { id: this.$route.params.shop_id })
        .catch(() => {
          this.$router.go(-1);
        });
    },
    showInvitationDialog() {
      this.dialog = true;
    }
  }
};
</script>
<style lang="scss" src="./global.scss" scoped></style>
