var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._b({
    attrs: {
      "persistent": "",
      "max-width": "460"
    }
  }, 'v-dialog', _vm.$attrs, false), [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" 店舗にユーザーを追加 ")]), _c('v-divider'), _c('validation-observer', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var invalid = _ref.invalid;
        return [_c('v-form', {
          attrs: {
            "lazy-validation": "",
            "autocomplete": "off"
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_c('v-card-text', {
          staticClass: "pt-2"
        }, [_c('v-container', [_c('v-row', [_c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" メールアドレス ")]), _c('v-col', {
          attrs: {
            "cols": "8"
          }
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required|email",
            "name": "email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                attrs: {
                  "dense": "",
                  "hide-details": "auto",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "email", $$v);
                  },
                  expression: "form.email"
                }
              })];
            }
          }], null, true)
        })], 1), _c('v-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(" アクセス権限 ")]), _c('v-col', {
          attrs: {
            "cols": "8"
          }
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "role"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('v-select', {
                attrs: {
                  "items": _vm.roles,
                  "dense": "",
                  "hide-details": "auto",
                  "error-messages": errors
                },
                model: {
                  value: _vm.form.user_role,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "user_role", $$v);
                  },
                  expression: "form.user_role"
                }
              })];
            }
          }], null, true)
        })], 1)], 1), _c('v-row', {
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "9"
          }
        }, [_vm._v(" 該当ユーザーに店舗ユーザーに追加した旨の メールが送信されます。 ")])], 1)], 1)], 1), _c('v-card-actions', {
          staticClass: "pb-8"
        }, [_c('v-spacer'), _c('v-btn', {
          staticClass: "mx-2 text-light-grey",
          attrs: {
            "width": "101",
            "height": "31",
            "depressed": "",
            "color": "transparent",
            "disabled": _vm.loading
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('close-dialog');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("cancel")))]), _c('v-btn', {
          staticClass: "mx-2",
          attrs: {
            "width": "101 ",
            "height": "31px",
            "color": "primary",
            "disabled": invalid,
            "loading": _vm.loading
          },
          on: {
            "click": _vm.submit
          }
        }, [_vm._v(" 追加 ")]), _c('v-spacer')], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }