<template>
  <v-dialog v-bind="$attrs" persistent max-width="460">
    <v-card>
      <v-card-title class="text-h5">
        店舗にユーザーを追加
      </v-card-title>

      <v-divider />

      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit" lazy-validation autocomplete="off">
          <v-card-text class="pt-2">
            <v-container>
              <v-row>
                <v-col cols="4" class="text-right">
                  メールアドレス
                </v-col>
                <v-col cols="8">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    name="email"
                  >
                    <v-text-field
                      v-model="form.email"
                      dense
                      hide-details="auto"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="4" class="text-right">
                  アクセス権限
                </v-col>
                <v-col cols="8">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="role"
                  >
                    <v-select
                      :items="roles"
                      v-model="form.user_role"
                      dense
                      hide-details="auto"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="9" class="text-center">
                  該当ユーザーに店舗ユーザーに追加した旨の
                  メールが送信されます。
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pb-8">
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2 text-light-grey"
              @click="$emit('close-dialog')"
              width="101"
              height="31"
              depressed
              color="transparent"
              :disabled="loading"
            >
              {{ $t("cancel") }}</v-btn
            >
            <v-btn
              class="mx-2"
              width="101 "
              height="31px"
              color="primary"
              @click="submit"
              :disabled="invalid"
              :loading="loading"
            >
              追加
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "InviteUserDialog",
  computed: {
    ...mapGetters(["getShop"]),
    roles() {
      return [
        {
          value: "P",
          text: "パートユーザー"
        },
        {
          value: "M",
          text: "マスターユーザー"
        }
      ];
    }
  },
  data() {
    return {
      form: {
        email: "",
        user_role: "P"
      },
      loading: false
    };
  },
  methods: {
    resetForm() {
      this.form = {
        email: "",
        user_role: "P"
      };
    },
    submit() {
      this.$refs.observer.validate().then(async success => {
        if (!success) {
          return;
        }
        this.loading = true;
        let params = this.form;
        params.shop_id = this.getShop.id;
        this.$store
          .dispatch("SHOP_INVITE_USER", params)
          .then(() => {
            this.$emit("close-dialog");
            this.$emit("reload-item");
            this.$store.dispatch("ALERT", {
              show: true,
              text: "登録内容を保存しました。"
            });
            this.resetForm();
          })
          .catch(error => {
            if (error.data.errors)
              this.$refs.observer.setErrors(error.data.errors);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    }
  }
};
</script>
