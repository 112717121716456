var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('CardHeader', {
    scopedSlots: _vm._u([{
      key: "left",
      fn: function fn() {
        return [_c('div', {
          staticClass: "pa-4"
        }, [_c('v-card', {
          attrs: {
            "color": "#525252",
            "flat": "",
            "height": "29px",
            "width": "176px"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-center align-center"
        }, [_c('v-icon', {
          attrs: {
            "color": "white",
            "size": "18"
          }
        }, [_vm._v("$info")]), _c('div', {
          staticClass: "font-18px white--text pl-2"
        }, [_vm._v(" " + _vm._s(_vm.$t("page_edit_store_title")) + " ")])], 1)])], 1)];
      },
      proxy: true
    }, {
      key: "right",
      fn: function fn() {
        return [_vm.formDisplay ? _c('div', {
          staticClass: "mr-8 mouse-pointer",
          on: {
            "click": function click($event) {
              _vm.formDisplay = false;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("$customChevronUp")])], 1) : _c('div', {
          staticClass: "mr-8 mouse-pointer",
          on: {
            "click": function click($event) {
              _vm.formDisplay = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("$customChevronDown")])], 1)];
      },
      proxy: true
    }])
  }), _c('v-expand-transition', [_c('validation-observer', {
    ref: "observer"
  }, [_c('v-form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formDisplay,
      expression: "formDisplay"
    }],
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-row', {
    staticClass: "main-form pt-7 pb-12",
    attrs: {
      "no-gutters": "",
      "justify": "space-around"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10",
      "xl": "5",
      "md": "6",
      "lg": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": "ID"
    }
  }, [_c('p', {
    staticClass: "font-weight-light text-blue pl-4 mt-4 mb-8 unedited"
  }, [_vm._v(" " + _vm._s(_vm.getShop ? _vm.getShop.id : "") + " ")])]), _c('InputFormElement', {
    attrs: {
      "important": true,
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_name')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_name'),
      "rules": "required:店舗名"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "full-width": "",
            "placeholder": "店舗名",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.store_name.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.store_name, "value", $$v);
            },
            expression: "fields.store_name.value"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "important": true,
      "label": _vm.$t('page_new_store_telephone_number')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_telephone_number'),
      "rules": "required:電話番号|tel|enter_half_width_numbers_hyphens"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "full-width": "",
            "placeholder": "電話番号",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.phone_number.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.phone_number, "value", $$v);
            },
            expression: "fields.phone_number.value"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_email_address')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_email_address'),
      "rules": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "placeholder": "メールアドレス",
            "full-width": "",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.mail_address.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.mail_address, "value", $$v);
            },
            expression: "fields.mail_address.value"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_representative')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_representative'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-text-field', {
          attrs: {
            "error-messages": errors,
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "placeholder": "代表者",
            "full-width": ""
          },
          model: {
            value: _vm.fields.representative.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.representative, "value", $$v);
            },
            expression: "fields.representative.value"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "10",
      "xl": "5",
      "md": "6",
      "lg": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "important": true,
      "label": _vm.$t('page_new_store_postal_codes')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_postal_codes'),
      "rules": "required:郵便番号"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "height": "27px",
            "flat": "",
            "outlined": "",
            "placeholder": "郵便番号",
            "full-width": "",
            "error-messages": errors
          },
          model: {
            value: _vm.fields.postal_code.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.postal_code, "value", $$v);
            },
            expression: "fields.postal_code.value"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_prefectures')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_prefectures'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-select', {
          attrs: {
            "error-messages": errors,
            "items": _vm.listPrefectures,
            "dense": "",
            "item-text": "name",
            "item-value": "id",
            "outlined": ""
          },
          model: {
            value: _vm.fields.prefectures.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.prefectures, "value", $$v);
            },
            expression: "fields.prefectures.value"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_city_address')
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "placeholder": "市区町村・番地"
    },
    model: {
      value: _vm.fields.city_address.value,
      callback: function callback($$v) {
        _vm.$set(_vm.fields.city_address, "value", $$v);
      },
      expression: "fields.city_address.value"
    }
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_room_number')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_room_number'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-text-field', {
          attrs: {
            "dense": "",
            "error-messages": errors,
            "placeholder": "建物名・部屋番号等",
            "outlined": ""
          },
          model: {
            value: _vm.fields.building_name_room_number.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.building_name_room_number, "value", $$v);
            },
            expression: "fields.building_name_room_number.value"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    staticClass: "mt-4",
    attrs: {
      "labelWidth": "3",
      "label": 'アプリ登録日'
    }
  }, [_c('p', {
    staticClass: "font-weight-light pl-4 unedited"
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.getShop.registration_date, "YYYY-MM-DD")) + " ")])])], 1)], 1), _c('v-row', {
    staticClass: "pt-11",
    attrs: {
      "no-gutters": "",
      "justify": "space-around"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "10",
      "xl": "5",
      "md": "6",
      "lg": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "label": _vm.$t('page_new_store_company_form'),
      "labelWidth": "3"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_company_form'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('v-select', {
          attrs: {
            "error-messages": errors,
            "items": _vm.listCompanyForm,
            "dense": "",
            "outlined": "",
            "item-text": "value",
            "item-value": "id"
          },
          model: {
            value: _vm.fields.company_type.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.company_type, "value", $$v);
            },
            expression: "fields.company_type.value"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    staticClass: "my-2",
    attrs: {
      "label": _vm.$t('page_new_store_fiscal_year_start_month'),
      "labelWidth": "3"
    }
  }, [_c('p', {
    staticClass: "pl-4 font-weight-light unedited"
  }, [_vm._v(" " + _vm._s("".concat(_vm.getShop.start_of_fiscal_year, "\u6708")) + " ")])])], 1), _c('v-col', {
    attrs: {
      "cols": "10",
      "xl": "5",
      "md": "6",
      "lg": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "important": true,
      "label": _vm.$t('page_new_store_registeration_date')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_registeration_date'),
      "rules": "required:登記日"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('DatePicker', {
          attrs: {
            "type": "YYYY-MM"
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref10) {
              var on = _ref10.on,
                  attrs = _ref10.attrs;
              return [_c('v-text-field', _vm._g(_vm._b({
                attrs: {
                  "dense": "",
                  "error-messages": errors,
                  "outlined": "",
                  "placeholder": _vm.$t('page_new_store_registeration_date_placeholder')
                },
                model: {
                  value: _vm.fields.registration_date.value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.fields.registration_date, "value", $$v);
                  },
                  expression: "fields.registration_date.value"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }], null, true),
          model: {
            value: _vm.fields.registration_date.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.registration_date, "value", $$v);
            },
            expression: "fields.registration_date.value"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "labelWidth": "3",
      "label": _vm.$t('page_new_store_number_of_employees')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('page_new_store_number_of_employees'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('v-select', {
          attrs: {
            "error-messages": errors,
            "items": _vm.listNumberOfEmployees,
            "dense": "",
            "outlined": "",
            "item-text": "value",
            "item-value": "id"
          },
          model: {
            value: _vm.fields.number_of_employees.value,
            callback: function callback($$v) {
              _vm.$set(_vm.fields.number_of_employees, "value", $$v);
            },
            expression: "fields.number_of_employees.value"
          }
        })];
      }
    }])
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "pb-9 pt-11",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', [_c('span', {
    staticClass: "pl-8 text-red font-weight-bold mouse-pointer",
    on: {
      "click": _vm.deleteShop
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("page_store_registeration_delete")) + " ")])]), _c('v-col', {
    staticClass: "d-flex justify-center text-light-grey"
  }, [_c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "width": "84px",
      "height": "24px",
      "type": "submit",
      "color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t("page_new_store_register")))])], 1), _c('v-col')], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }