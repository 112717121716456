var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "frag",
      rawName: "v-frag"
    }]
  }, [_c('Parent'), _c('ShopEdit', {
    staticClass: "mt-3"
  }), _c('SharedAccount', {
    staticClass: "mt-8"
  }), _c('InviteUserDialog', {
    on: {
      "close-dialog": function closeDialog($event) {
        _vm.dialog = false;
      },
      "reload-item": _vm.getDataFromApi
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }), _c('CardHeader', {
    staticClass: "mt-8"
  }, [_c('template', {
    slot: "left"
  }, [_c('div', {
    staticClass: "pa-5"
  }, [_c('v-card', {
    staticClass: "ml-1",
    attrs: {
      "color": "#525252",
      "flat": "",
      "height": "28px",
      "width": "128px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('v-icon', {
    attrs: {
      "color": "white",
      "size": "20"
    }
  }, [_vm._v("$users")]), _c('div', {
    staticClass: "font-18px white--text pl-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("user_name")) + " ")])], 1)])], 1)]), _c('template', {
    slot: "right"
  }, [_c('v-row', {
    staticClass: "mr-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.showInvitationDialog
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" mdi-plus ")]), _vm._v(" 新規登録 ")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "ripple": false,
      "text": "",
      "fab": "",
      "x-small": "",
      "color": "#525252"
    },
    on: {
      "click": function click($event) {
        _vm.showTables = !_vm.showTables;
      }
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.chevronIcon) + " ")])], 1)], 1)], 1)], 1)], 2), _c('v-expand-transition', [_c('Table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showTables,
      expression: "showTables"
    }],
    staticClass: "edit-table",
    attrs: {
      "headers": _vm.userHeaders,
      "items": _vm.users,
      "total-records": _vm.users.length
    },
    scopedSlots: _vm._u([{
      key: "item.user_role",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("showRoles")(item.user_role)) + " ")];
      }
    }, {
      key: "item.avatar",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-avatar', {
          staticClass: "white--text",
          attrs: {
            "color": "avatar-bg-grey",
            "size": "36"
          }
        }, [_c('v-img', {
          attrs: {
            "src": item.avatar
          }
        })], 1)];
      }
    }, {
      key: "item.surname",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.surname + " " + item.name) + " ")];
      }
    }, {
      key: "item.created_at",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.created_at, "YYYY-MM-DD")) + " ")];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('v-btn', {
          attrs: {
            "text": "",
            "rounded": "",
            "to": {
              name: 'UserShopEdit',
              params: {
                shop_id: _vm.getShop.id,
                user_id: item.user_shop_id
              }
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "16"
          }
        }, [_vm._v("$edit")])], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }