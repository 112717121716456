var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('CardHeader', [_c('template', {
    slot: "left"
  }, [_c('div', {
    staticClass: "pa-4"
  }, [_c('v-card', {
    attrs: {
      "color": "#525252",
      "flat": "",
      "height": "29px",
      "width": "176px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('div', {
    staticClass: "font-18px white--text"
  }, [_vm._v(" 共有アカウント ")])])])], 1)]), _c('template', {
    slot: "right"
  }, [_vm.sharedAccountMode && _vm.sharedAccountMode == 'edit' ? [_c('p', {
    staticClass: "mr-8  mouse-pointer"
  }, [_vm.formDisplay ? _c('v-icon', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.formDisplay = false;
      }
    }
  }, [_vm._v("$customChevronUp")]) : _c('v-icon', {
    attrs: {
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.formDisplay = true;
      }
    }
  }, [_vm._v("$customChevronDown")])], 1)] : [_c('v-btn', {
    staticClass: "font-14px mr-8",
    attrs: {
      "color": "transparent",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeToCreateMode();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-plus")]), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v("新規登録")])], 1)]], 2)], 2), _c('v-expand-transition', [_c('validation-observer', {
    ref: "observer"
  }, [_c('v-form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.formDisplay,
      expression: "formDisplay"
    }],
    ref: "form",
    staticClass: "shared-cls",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-row', {
    staticClass: "pt-8 pb-6",
    attrs: {
      "dense": "",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    staticClass: "ml-13",
    attrs: {
      "cols": "5"
    }
  }, [_c('InputFormElement', {
    attrs: {
      "useCompute": false,
      "labelInputSpace": false,
      "labelWidth": "2",
      "label": "ID"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "id",
      "rules": "required|min:8"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-text-field', {
          staticClass: "ml-4 mr-8",
          attrs: {
            "dense": "",
            "height": "27px",
            "outlined": "",
            "placeholder": "ID",
            "error-messages": errors
          },
          model: {
            value: _vm.accountID,
            callback: function callback($$v) {
              _vm.accountID = $$v;
            },
            expression: "accountID"
          }
        })];
      }
    }])
  })], 1), _c('InputFormElement', {
    attrs: {
      "useCompute": false,
      "labelWidth": "2",
      "labelInputSpace": false,
      "label": _vm.$t('page_store_shared_password')
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password",
      "rules": "required:パスワード |min:8"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-text-field', {
          staticClass: "ml-4 mr-8",
          attrs: {
            "dense": "",
            "height": "27px",
            "outlined": "",
            "placeholder": "パスワード",
            "error-messages": errors
          },
          model: {
            value: _vm.accountPassword,
            callback: function callback($$v) {
              _vm.accountPassword = $$v;
            },
            expression: "accountPassword"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-col', {
    staticClass: "mr-15",
    attrs: {
      "cols": "5"
    }
  }, [_c('InputFormElement', {
    staticClass: "suggestions",
    attrs: {
      "label": _vm.$t('member'),
      "labelInputSpace": false,
      "useCompute": false,
      "labelWidth": "2"
    }
  }, [_c('div', {
    staticClass: "member-list ml-4 px-3"
  }, _vm._l(_vm.members, function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "d-flex align-baseline "
    }, [_c('span', {
      staticClass: "text-a0a0a0"
    }, [_vm._v(_vm._s(item.id) + ".")]), _c('v-text-field', {
      staticClass: "ml-2",
      attrs: {
        "height": "24",
        "flat": "",
        "dense": "",
        "hide-details": "",
        "placeholder": "追加のメンバー名を入力してください。"
      },
      on: {
        "keyup": function keyup($event) {
          return _vm.addNewField();
        }
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1);
  }), 0)])], 1)], 1), _c('v-row', {
    staticClass: "pb-8",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-btn', {
    staticClass: "ml-8 text-red font-weight-bold ",
    attrs: {
      "text": "",
      "loading": _vm.loading,
      "disabled": _vm.sharedAccountMode != 'edit'
    },
    on: {
      "click": _vm.openConfirmation
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("page_store_registeration_delete")) + " ")])], 1), _c('v-col', {
    staticClass: "d-flex justify-center"
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "width": "84px",
      "height": "24px",
      "type": "submit",
      "color": "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t("page_store_shared_account_save")))])], 1), _c('v-col')], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }